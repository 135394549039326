import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import MasterService from "../../../service/MasterService";
import { toast } from "react-toastify";

class ViewIFCategoryHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ifCategoryListingHistory: [],
            ifHistoryCategoryId: props.ifHistoryCategoryId,
            ifHistoryCategoryName: props.ifHistoryCategoryName,

            loading: false,
        };
    }

    getIFCategoryListHistory = () => {
        this.setState({ loading: true })
        MasterService.post(`/dealer/inventory-funding/get_if_category_list_history`, { category_id: this.state.ifHistoryCategoryId }).then((response) => {
            if (response && response.data && response.data.status === 200) {
                let responseData = response.data.data;
                this.setState({ loading: false })
                this.setState({ ifCategoryListingHistory: responseData });
            } else {
                this.setState({ loading: false })
                toast.error(response.data.message);
            }
        }).catch((error) => {
            this.setState({ loading: false })
            toast.error(error)
        });
    };

    componentDidMount() {
        this.getIFCategoryListHistory();
    }

    componentWillReceiveProps(nextProps) {
        this.setState({
            ifHistoryCategoryId: nextProps.ifHistoryCategoryId,
            ifHistoryCategoryName: nextProps.ifHistoryCategoryName,
        });
    }

    render() {
        return (
            <div className={this.state.loading ? "container-fluid loading" : "container-fluid"}>
                <table style={{ width: "100%", tableLayout: "fixed" }}>
                    <thead>
                        <tr>
                            <th>{this.props.t("Listing.IF_History_Listing.Date")}</th>
                            <th>{this.props.t("Listing.IF_History_Listing.Changed_By")}</th>
                            <th>{this.props.t("Listing.IF_History_Listing.Interest_Fee")}</th>
                            <th>{this.props.t("Listing.IF_History_Listing.Provision_Fee")}</th>
                            <th>{this.props.t("Listing.IF_History_Listing.Admin_Fee")}</th>
                            <th>{this.props.t("Listing.IF_History_Listing.Renewal_Fee")}</th>
                            <th>{this.props.t("Listing.IF_History_Listing.Renewal_Time")}</th>
                            <th>{this.props.t("Listing.IF_History_Listing.Loan_Tenure")}</th>
                            <th>{this.props.t("Listing.IF_History_Listing.Penalty_Interest")}</th>
                            <th>{this.props.t("Listing.IF_History_Listing.category_cashback")}</th>
                            <th>{this.props.t("Listing.IF_History_Listing.ltv")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {this.state.ifCategoryListingHistory && this.state.ifCategoryListingHistory.length > 0 ? (
                            this.state.ifCategoryListingHistory.map((item, idx) => {
                                return (
                                    <tr key={idx}>
                                        <td>{item.created_date}</td>
                                        <td>{item.name}</td>
                                        <td>{item.interest_fee}%</td>
                                        <td>{item.provision_fee}%</td>
                                        <td>{item.admin_fee}{item.admin_fee_type == 1 ? "%" : ''} </td>
                                        <td>{item.renewal_fee} {item.renewal_fee_type == 1 ? "%" : ''}</td>
                                        <td>{item.renewal_time} Days</td>
                                        <td>{item.loan_tenure} Days</td>
                                        <td>{item.penalty_interest}%</td>
                                        <td>{item.category_cashback}%</td>
                                        <td>{item.ltv == null ? `-` : `${item.ltv}%`}</td>
                                    </tr>
                                );
                            })
                        ) : (
                            <tr>
                                <td colSpan="9">
                                    <h6>No Records Found</h6>
                                </td>
                            </tr>
                        )}
                    </tbody>
                </table>

            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData }),
    };
};

export default withTranslation("inventory_funding")(connect(mapStateToProps, mapDispatchToProps)(ViewIFCategoryHistory));
