import MasterService from "./MasterService";

const IfApplicationService = {
    ...MasterService,

    getApplicationDetails(postData) {
        return this.post("/dealer/inventory-funding/get_all_if_details", postData || {});
    },

    getIFMasterData(postData) {
        return this.post("/dealer/inventory-funding/get_if_master_data", postData || {});
    },

    ifDocRequestResubmission(postData) {
        return this.post("/dealer/inventory-funding/if_doc_request_resubmission", postData || {});
    },

    ifMarkDocApproved(postData) {
        return this.post("/dealer/inventory-funding/if_mark_doc_approved", postData || {});
    },

    replaceIFDoc(postData) {
        return this.post("/dealer/inventory-funding/replace_if_doc", postData || {});
    },

    saveAddnDoc(postData) {
        return this.post("/dealer/inventory-funding/if_save_addn_doc", postData || {});
    },

    saveIFDocBackend(postData) {
        return this.post("/dealer/inventory-funding/save_if_doc_backend", postData || {});
    },

    updateIFInformation(postData) {
        return this.post("/dealer/inventory-funding/update_if_info", postData || {});
    },

    updateIFDVFInformation(postData) {
        return this.post("/dealer/inventory-funding/update_if_dvf_info", postData || {});
    },

    saveIFFinalSubmission(postData) {
        return this.post("/dealer/inventory-funding/save_if_final_submission", postData || {});
    },

    rejectIFApplication(postData) {
        return this.post("/dealer/inventory-funding/reject_if_application", postData || {});
    },

    reopenIFApplication(postData) {
        return this.post("/dealer/inventory-funding/reopen_if_application", postData || {});
    },
};

export default IfApplicationService;
