import React from 'react';
import ReactDOM from 'react-dom';
// import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { BrowserRouter } from 'react-router-dom';
import reducer from './store/reducer/reducer';
import thunkMiddleware from 'redux-thunk'
import { composeWithDevTools } from '@redux-devtools/extension'
import { createStore,applyMiddleware } from 'redux';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import i18next from 'i18next';
// import common_id from "./translations/id/common.json";
// import common_en from "./translations/en/common.json";
// import inventory_id from "./translations/id/inventory.json";
// import inventory_en from "./translations/en/inventory.json";
// import dealer_id from "./translations/id/dealer.json";
// import dealer_en from "./translations/en/dealer.json";
// import lead_id from "./translations/id/lead.json";
// import lead_en from "./translations/en/lead.json";
// import ac_manager_id from "./translations/id/ac_manager.json";
// import ac_manager_en from "./translations/en/ac_manager.json";
import secureStorage from './config/encrypt';

import AllTranslation from './translations/translation';
import _ from  'lodash';
import ifApplicationReducer from './store/reducer/ifApplicationReducer';

const composedEnhancer = composeWithDevTools(applyMiddleware(thunkMiddleware))
const getEnTranslationJson = (file) => {
    return (AllTranslation[`${file}_${process.env.REACT_APP_CONFIG_FILE_NAME}`]) ? 
                _.merge(AllTranslation[file], AllTranslation[`${file}_${process.env.REACT_APP_CONFIG_FILE_NAME}`]) :
                AllTranslation[file];
}

i18next.init({
    interpolation: { escapeValue: false },
    lng: secureStorage.getItem('lang') || 'en',
    resources: {
        en: {
            common:  getEnTranslationJson('common_en'),
            inventory: getEnTranslationJson('inventory_en'),
            dealers: getEnTranslationJson('dealer_en'),
            lead: getEnTranslationJson('lead_en'),
            ac_manager: getEnTranslationJson('ac_manager_en'),
            sales_support_users: getEnTranslationJson('sales_support_users_en'),
            inventory_funding: getEnTranslationJson('inventory_funding_en')
        },
        id: {
            common: AllTranslation.common_id,
            inventory: AllTranslation.inventory_id,
            dealers: AllTranslation.dealer_id,
            lead: AllTranslation.lead_id,
            ac_manager: AllTranslation.ac_manager_id,
            sales_support_users: AllTranslation.sales_support_users_id,
            inventory_funding : AllTranslation.inventory_funding_id
        },
    },
});

const store = createStore(
    (state = {}, action) => ({
        ...reducer(state, action),
        ifApplication: ifApplicationReducer(state.ifApplication, action)
    }),
    composedEnhancer
);


ReactDOM.render(
    <I18nextProvider i18n={i18next}>
        <Provider store={store}>
            <BrowserRouter>
                <App />
            </BrowserRouter>
        </Provider>
    </I18nextProvider>,
    document.getElementById('root'));

serviceWorker.unregister();
