import React, { Component } from 'react';
import './webroot/css/main.css';
//import "./App.scss";
import InternationalRoute from './routes/route';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { CONFIG_FILE_CHANGE } from './config/constant';

class App extends Component {
  // constructor(props) {//need to remove
  //   super(props);
  // }
  componentDidMount = ()=>{
    const favicon = document.getElementById('favicon');
    favicon.href=CONFIG_FILE_CHANGE.FAV_ICON
  }
  render() {
    return (
      <div className="App">
        <ToastContainer position={toast.POSITION.TOP_RIGHT} pauseOnFocusLoss={false} />
        <InternationalRoute />
      </div>
    );
  }
}

export default App;
