const initialState = {
    applicationDetails: {},
    ifMasterData: {},
    loading: false,
    error: null,
    unreadCount: 0,
};

const ifApplicationReducer = (state = initialState, action) => {
    switch (action.type) {
        case "SET_LOADING":
            return {
                ...state,
                loading: action.payload,
            };
        case "SET_ERROR":
            return {
                ...state,
                error: action.payload,
            };
        case "CLEAR_ERROR":
            return {
                ...state,
                error: null,
            };
        case "SET_APPLICATION_DETAILS":
            return {
                ...state,
                applicationDetails: action.payload,
            };
        case "SET_IF_MASTER_DATA":
            return {
                ...state,
                ifMasterData: action.payload,
            };
        case "SET_UNREAD_COUNT":
            return {
                ...state,
                unreadCount: action.payload,
            };
        default:
            return state;
    }
};

export default ifApplicationReducer;
