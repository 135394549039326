import IfApplicationService from "../../service/IfApplicationService";

export const setIFLoading = (isLoading) => ({
    type: "SET_LOADING",
    payload: isLoading,
});

export const setIFError = (error) => ({
    type: "SET_ERROR",
    payload: error,
});

export const clearIFError = () => ({
    type: "CLEAR_ERROR",
});

export const setUnreadCount = (count) => ({
    type: "SET_UNREAD_COUNT",
    payload: count,
});

export const getApplicationDetails = (application_id) => {
    return async (dispatch) => {
        try {
            dispatch(setIFLoading(true));
            dispatch(clearIFError());
            const response = await IfApplicationService.getApplicationDetails({ application_id });
            if (response?.status === 200 && response?.data) {
                dispatch(success(response.data.data));
            } else {
                dispatch(success({}));
            }
        } catch (err) {
            dispatch(setIFError(err.message));
            dispatch(success({}));
        } finally {
            dispatch(setIFLoading(false));
        }
    };

    function success(payload) {
        return { type: "SET_APPLICATION_DETAILS", payload };
    }
};

export const getIFMasterData = () => {
    return async (dispatch) => {
        try {
            dispatch(setIFLoading(true));
            dispatch(clearIFError());
            const response = await IfApplicationService.getIFMasterData({});
            if (response?.status === 200 && response?.data) {
                dispatch(success(response.data.data));
            } else {
                dispatch(success({}));
            }
        } catch (err) {
            dispatch(setIFError(err.message));
            dispatch(success({}));
        } finally {
            dispatch(setIFLoading(false));
        }
    };

    function success(payload) {
        return { type: "SET_IF_MASTER_DATA", payload };
    }
};
