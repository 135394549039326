import React, { Component } from "react";
import { withTranslation } from "react-i18next";
import { connect } from "react-redux";
import Button from "./../../elements/Button";
import InputField from "./../../elements/Input";
import MasterService from "../../../service/MasterService";
import Select from "react-select";
import { toast } from "react-toastify";
import debounce from "lodash/debounce";
import ModalPopup from "../../elements/Popup";

const SEARCH_TYPE = Object.freeze([
    { value: "dealer_name", label: "Dealer Name" },
    { value: "dealer_mobile", label: "Mobile" },
    { value: "dealer_email", label: "Email" },
    { value: "application_id", label: "Application ID" },
]);

class DealerVerificationMapping extends Component {
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            searchResults: [],
            selectedApplication: props?.ifMappedApplicationObject
                ? {
                      id: props.ifMappedApplicationObject.id,
                      label: `${props.ifMappedApplicationObject.label} - (App ID - ${props.ifMappedApplicationObject.id})`,
                      value: props.ifMappedApplicationObject.value,
                  }
                : null,
            selectedDealer: {
                value: props.basicDetails?.gcd_code || "",
                label: `${props.basicDetails?.organization || ""}: (${props.basicDetails?.gcd_code || ""})`,
            },
            errors: {},
            searchType: "",
            searchText: "",
            isSearching: false,
            isMapped: !!props.ifMappedApplicationObject,
            showConfirmModal: false,
        };
        this.debouncedSearch = debounce(this.performSearch, 500);
    }

    handleSearchTypeChange = (selected) => {
        if (!this.state.isMapped) {
            this.setState({
                searchType: selected.value,
                searchResults: [],
                selectedApplication: null,
            });
        }
    };

    handleSearchTextChange = (e) => {
        if (!this.state.isMapped) {
            const searchText = e.target.value;
            this.setState(
                {
                    searchText,
                    isSearching: true,
                },
                () => {
                    if (searchText.length >= 1) {
                        this.debouncedSearch();
                    } else {
                        this.setState({
                            searchResults: [],
                            isSearching: false,
                        });
                    }
                }
            );
        }
    };

    performSearch = async () => {
        const { searchType, searchText } = this.state;

        if (!searchType || !searchText) {
            return;
        }

        try {
            const response = await MasterService.post("/dealer/inventory-funding/get_unmapped_applications", {
                search_type: searchType,
                search_text: searchText,
            });

            if (response?.data?.status === 200) {
                const formattedResults = (response.data.data || []).map((item) => ({
                    value: item.application_id,
                    label: `${item.dealer_name} - (App ID - ${item.application_id})`,
                    ...item,
                }));

                this.setState({
                    searchResults: formattedResults,
                    isSearching: false,
                });
            } else {
                toast.error(response?.data?.message || "Failed to fetch results");
                this.setState({ isSearching: false });
            }
        } catch (error) {
            toast.error(error.message || "An error occurred while searching");
            this.setState({ isSearching: false });
        }
    };

    handleSubmit = async () => {
        const { selectedApplication } = this.state;
        const { basicDetails } = this.props;

        if (!selectedApplication) {
            toast.error("Please select both application ");
            return;
        }

        this.setState({ loading: true });
        try {
            const response = await MasterService.post("/dealer/inventory-funding/save_dealer_if_mapping", {
                application_id: selectedApplication.value,
                dealer_id: basicDetails.id,
            });

            if (response?.data?.status === 200) {
                toast.success("Dealer mapping saved successfully");
                this.setState({
                    isMapped: true,
                });

                if (this.props.onSuccess) {
                    this.props.onSuccess();
                }
            } else {
                toast.error(response?.data?.message || "Failed to save dealer mapping");
            }
        } catch (error) {
            toast.error(error.message || "An error occurred while saving the mapping");
        } finally {
            this.setState({ loading: false });
        }
    };

    handleSubmitClick = () => {
        this.setState({ showConfirmModal: true });
    };

    handleConfirmSubmit = async () => {
        this.setState({ showConfirmModal: false });
        await this.handleSubmit();
    };

    handleCancelSubmit = () => {
        this.setState({ showConfirmModal: false });
    };

    render() {
        const { loading, searchResults, isSearching, searchText, selectedApplication, selectedDealer, isMapped, showConfirmModal } = this.state;

        return (
            <div className={loading ? "loading" : ""}>
                <div className="card-body">
                    <div className="row">
                        <div className="col-sm-4 form-group">
                            <label>
                                {this.props.t("dealerDetails.Inventory_Funding_Details.Search_Type")}{" "}
                                <span className="required" style={{ color: "red" }}>
                                    *
                                </span>
                            </label>

                            <Select
                                placeholder="Select Search Type"
                                options={SEARCH_TYPE}
                                onChange={this.handleSearchTypeChange}
                                isDisabled={loading || isMapped}
                                value={SEARCH_TYPE.find((type) => type.value === this.state.searchType)}
                            />
                        </div>
                        <div className="col-sm-4">
                            <InputField
                                type="text"
                                name="search_text"
                                id="search_text"
                                placeholder={this.props.t("dealerDetails.Inventory_Funding_Details.Search_Text")}
                                value={searchText}
                                label={this.props.t("dealerDetails.Inventory_Funding_Details.Search_Text")}
                                onChange={this.handleSearchTextChange}
                                required={true}
                                disabled={loading || !this.state.searchType || isMapped}
                            />
                        </div>
                    </div>

                    <div className="row mt-4">
                        <div className="col-sm-6">
                            <label>{this.props.t("dealerDetails.Inventory_Funding_Details.IF_Mapping_Application_Data")}</label>
                            <Select
                                placeholder={isSearching ? `Searching...` : this.props.t("dealerDetails.Inventory_Funding_Details.IF_Mapping_Application_Data")}
                                options={searchResults}
                                onChange={(selected) => this.setState({ selectedApplication: selected })}
                                value={selectedApplication}
                                isLoading={isSearching}
                                isDisabled={loading || isMapped}
                            />
                        </div>
                        <div className="col-sm-6">
                            <label>{this.props.t("dealerDetails.Inventory_Funding_Details.IF_Mapping_Dealer_Data")}</label>
                            <Select
                                placeholder={this.props.t("dealerDetails.Inventory_Funding_Details.IF_Mapping_Dealer_Data")}
                                options={[selectedDealer]}
                                value={selectedDealer}
                                isDisabled={true}
                            />
                        </div>
                    </div>
                </div>

                <div className="card-footer text-center m-xl-t">
                    {!isMapped && (
                        <Button title="Submit" btnClass="btn btn-primary" onClick={this.handleSubmitClick} disabled={loading || !selectedApplication} />
                    )}
                </div>
                {showConfirmModal && (
                    <ModalPopup id="confirm_dealer_mapping" className="modal confirm-modal" title="Confirm Dealer Mapping" modalClose={this.handleCancelSubmit}>
                        <div className="modal-body">
                            <p>Are you sure you want to map this dealer with the selected application? This action cannot be undone.</p>
                        </div>
                        <div className="modal-footer">
                            <Button title="Cancel" btnClass="btn btn-secondary" onClick={this.handleCancelSubmit} />
                            <Button title="Confirm" btnClass="btn btn-primary" onClick={this.handleConfirmSubmit} />
                        </div>
                    </ModalPopup>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    user_loggedin: state.userLoggedIn,
});

export default withTranslation("dealers")(connect(mapStateToProps)(DealerVerificationMapping));
