import React, { useEffect, useState } from "react";
import { useParams, Redirect } from "react-router-dom";
import { withTranslation } from "react-i18next";
import { connect, useDispatch, useSelector } from "react-redux";

import secureStorage from "../../../config/encrypt";
import IFApplicationRibbon from "./IFApplicationRibbon";
import IFApplicationMenu from "./IFApplicationMenu";
import { getApplicationDetails, getIFMasterData } from "../../../store/action/ifApplicationAction";

const IFApplicationDetails = (props) => {
    const dispatch = useDispatch();
    const { application_id } = useParams();
    const [redirect, setRedirect] = useState("");
    const loading = useSelector((state) => state.ifApplication.loading);

    const updateReduxData = async () => {
        const loginResponse = secureStorage.getItem("loginUserInfo");
        if (!loginResponse) {
            setRedirect("/login");
            return;
        }
        props.updateStateIntoRedux("LOGIN_USER", loginResponse);
        const userRole = loginResponse?.data?.user_data?.role;
        if (userRole !== "admin") {
            setRedirect("/");
        }
    };

    useEffect(() => {
        updateReduxData();
    }, []);

    useEffect(() => {
        if (redirect) {
            props.history.push(redirect);
        }
    }, [redirect, props.history]);

    useEffect(() => {
        dispatch(getApplicationDetails(application_id));
        dispatch(getIFMasterData());
    }, [dispatch]);

    if (redirect) {
        return <Redirect to={"/admin/ac-manager"} />;
    }

    return (
        <div className={`container-fluid ${loading ? "loading" : ""}`}>
            <div className="row">
                <IFApplicationRibbon />
                <IFApplicationMenu />
            </div>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
    };
};
const mapDispatchToProps = (dispatch) => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData }),
    };
};
export default withTranslation("if_application")(connect(mapStateToProps, mapDispatchToProps)(IFApplicationDetails));
