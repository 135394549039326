import React, { Component } from "react";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import ModalPopup from "../../elements/Popup";
import ViewIFCategoryHistory from "./ViewIFCategoryHistory";
import IFCategoryManagement from "./IFCategoryManagement";
import MasterService from "../../../service/MasterService";
import { toast } from "react-toastify";
import Button from './../../elements/Button';
import secureStorage from './../../../config/encrypt';
import { NumericFormat as NumberFormat } from "react-number-format";

class IFCategoryListing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            formData: {},
            ifCategoryListing: props.ifCategoryListing,
            ifCategoryListingHistory: props.ifCategoryListingHistory,
            adminUserData: props.adminUserData,
            ifHistoryPopup: false,
            ifEditCategoryPopup: false,
            ifHistoryCategoryId: 0,
            ifHistoryCategoryName: "",
            category_inactive: false,
            confirmData:{},
            loading: false,
        };
    }

    handleIfEditCategory = (event, categoryId) => {
        this.setState({ loading: true });
        MasterService.post(`/dealer/inventory-funding/get_if_category_list`, { category_id: categoryId })
            .then((response) => {
                if (response && response.data && response.data.status === 200) {
                    let responseData = response.data.data;
                    this.setState({ formData: responseData });
                    this.setState({ ifEditCategoryPopup: true });
                    this.setState({ loading: false });
                } else {
                    toast.error(response.data.message);
                    this.setState({ loading: false });
                }
            })
            .catch((error) => {
                this.setState({ loading: false });
                toast.error(error);
            });
    };

    handleIfStatus = (params) => {
        this.setState({ loading: true });
        MasterService.post(`/dealer/inventory-funding/change_if_category_status`, params)
            .then((response) => {
                if (response && response.data && response.data.status === 200) {
                    toast.success(response.data.message);
                    this.setState({ loading: false,category_inactive:false });
                    this.props.getIFCategoryListAll();
                } else {
                    toast.error(response.data.message);
                    this.setState({ loading: false,category_inactive:false });
                }
            })
            .catch((error) => {
                toast.error(error);
                this.setState({ loading: false });
            });
    };

    componentWillReceiveProps(nextProps) {
        this.setState({
            ifCategoryListing: nextProps.ifCategoryListing,
            ifCategoryListingHistory: nextProps.ifCategoryListingHistory,
            adminUserData: nextProps.adminUserData,
            formData: nextProps.formData,
        });
    }

    handleIfHistory = (event, ifCategoryId, ifCategoryName) => {
        this.setState({ ifHistoryCategoryId: ifCategoryId });
        this.setState({ ifHistoryCategoryName: ifCategoryName });
        this.setState({ ifHistoryPopup: true });
    };

    closeIfHistoryModel = () => {
        this.setState({ ifHistoryPopup: false });
    };

    closeIfEditCategoryModel = () => {
        this.setState({ ifEditCategoryPopup: false });
        this.props.getIFCategoryListAll();
    };

    showConfirmModal = (event,type,params) => {
        if(type === 'is_active'){
            params.is_active = event.target.checked ? 1 : 0;
        }
        this.setState({ category_inactive: true ,confirmData:{event,type,params} });
    }
    closeConfirmModal = () => {
        this.setState({ category_inactive: false });
    }

    render() {
        let role_access = []
        if (secureStorage.getItem('loginUserInfo')) {
            let loginResponse = secureStorage.getItem('loginUserInfo');
            role_access = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role_data && loginResponse.data.user_data.role_data.length > 0 ? loginResponse.data.user_data.role_data : []
        }
        return (
            <div className={this.state.loading ? "container-fluid loading result-wrap" : "container-fluid result-wrap"}>
                <div className="card">
                    <div className="pad-15">
                        <div className="row">
                            <div className="col-sm-8"></div>
                            <div className="col-sm-4 text-right pad-t10"></div>
                        </div>
                    </div>
                    <div className="clearfix">
                        <div className="table-responsive">
                            <table className="table table-bordered">
                                <thead>
                                    <tr>
                                        <th>{this.props.t("Listing.IF_Category_Listing.Category_Name")}</th>
                                        <th>{this.props.t("Listing.IF_Category_Listing.Interest_Fee")}</th>
                                        <th>{this.props.t("Listing.IF_Category_Listing.Provision_Fee")}</th>
                                        <th>{this.props.t("Listing.IF_Category_Listing.Admin_Fee")}</th>
                                        <th>{this.props.t("Listing.IF_Category_Listing.Renewal_Fee")}</th>
                                        <th>{this.props.t("Listing.IF_Category_Listing.Renewal_Time")}</th>
                                        <th>{this.props.t("Listing.IF_Category_Listing.Loan_Tenure")}</th>
                                        <th>{this.props.t("Listing.IF_Category_Listing.Penalty_Interest")}</th>
                                        <th>{this.props.t("Listing.IF_Category_Listing.Penalty_Fee")}</th>
                                        <th>{this.props.t("Listing.IF_Category_Listing.Ltv")}</th>
                                        <th>{this.props.t("Listing.IF_Category_Listing.category_cashback")}</th>
                                        <th>{this.props.t("Listing.IF_Category_Listing.Action")}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {this.state.ifCategoryListing.length === 0 ? (
                                        <tr>
                                            <td align="center" colSpan="7">
                                                <h6 className="text-center text-danger text-bold">{this.props.t("Listing.No_Record_Found")}</h6>
                                            </td>
                                        </tr>
                                    ) : null}
                                    {this.state.ifCategoryListing.length !== 0 &&
                                        this.state.ifCategoryListing.map((item, idx) => {
                                            return (
                                                <tr key={idx}>
                                                    <td>{item.category_name}</td>
                                                    <td>{item.interest_fee}%</td>
                                                    <td>{item.provision_fee}%</td>
                                                    <td>{item.admin_fee}{item.admin_fee_type == 1 ? "%" :''} </td>
                                                    <td>{item.renewal_fee}{item.renewal_fee_type == 1 ? "%" :''}</td>
                                                    <td>{item.renewal_time} Days</td>
                                                    <td>{item.loan_tenure} Days</td>
                                                    <td>{item.penalty_interest}%</td>
                                                    <td>Rp <NumberFormat thousandSeparator={"."}  decimalSeparator={","} displayType={"text"} value={parseFloat(item.penalty_fee)} /></td>
                                                    <td>{item.ltv == null ? `-` : `${item.ltv}%`}</td>
                                                    <td>{item.category_cashback}%</td>
                                                    <td>
                                                        <div className="btn-group btn-group-small mrg-r10">
                                                            {
                                                                    role_access.includes("inventory_funding_category_edit") &&   <button
                                                                    className="btn btn-default"
                                                                    type="button"
                                                                    title="Edit Category"
                                                                    onClick={(e) => {
                                                                        this.handleIfEditCategory(e, item.category_id);
                                                                    }}
                                                                >
                                                                    <i className="ic-createmode_editedit icons-normal"></i>
                                                                </button>
                                                            }

                                                            {
                                                                    role_access.includes("inventory_funding_category_delete") &&   <button
                                                                    className="btn btn-default"
                                                                    type="button"
                                                                    title="Delete Category"
                                                                    onClick={(e) => {
                                                                        this.showConfirmModal(e, 'status', { id:item.id,category_name:item.category_name,category_id: item.category_id, status: 0 })
                                                                    }}
                                                                >
                                                                    <i className="ic-delete icons-normal"></i>
                                                                </button>
                                                            }

                                                            <button
                                                                className="btn btn-default"
                                                                type="button"
                                                                title="View Category History"
                                                                onClick={(e) => this.handleIfHistory(e, item.category_id, item.category_name)}
                                                            >
                                                                <i className="ic-access_timequery_builderschedule icons-normal"></i>
                                                            </button>

                                                            { role_access.includes("inventory_funding_category_ status_change") ?item.status == 1 ? (
                                                                <>
                                                                    <label className="switch-btn btn btn-link" htmlFor={"active" + item.id}>
                                                                         <input className="switch-btn" 
                                                                            id={"active" + item.id}
                                                                            name={"active" + item.id} type="checkbox" 
                                                                            onClick={(e) => {
                                                                                this.showConfirmModal(e, 'is_active', { id:item.id,category_name:item.category_name,category_id: item.category_id })
                                                                            }}
                                                                            checked={(item.is_active == 1) ? true : false} />
                                                                        <div className="slider round"></div>
                                                                        <span className="switch-label"></span>
                                                                        {this.props.t("Listing.Active")}
                                                                    </label>
                                                                </>
                                                            ) : (
                                                                ""
                                                            ) : ''}
                                                        </div>
                                                    </td>
                                                </tr>
                                            );
                                        })}
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                {this.state.ifHistoryPopup === false ? null : (
                    <ModalPopup
                        id="if_view_history"
                        className="modal ViewHistory data-table category-history-popup"
                        //title={`${this.state.ifHistoryCategoryName} - Category History`}
                        title={`Category History`}
                        modalClose={this.closeIfHistoryModel}
                    >
                        {this.state.ifHistoryCategoryId !== 0 && (
                            <ViewIFCategoryHistory
                                ifHistoryCategoryId={this.state.ifHistoryCategoryId}
                                ifHistoryCategoryName={this.state.ifHistoryCategoryName}
                                formData={this.state.formData}
                            />
                        )}
                    </ModalPopup>
                )}

                {this.state.category_inactive === false ? null : (
                    <ModalPopup
                        id="if_view_history"
                        className="modal"
                        title={this.props.t("Listing.CONFIRM")}
                        modalClose={this.closeConfirmModal}
                    >
                        {this.state.confirmData ?<div>

                            {
                                this.state.confirmData.type == 'status' ? <p>{this.props.t("Listing.delete_category") } <b>{`"${this.state.confirmData.params.category_name}"`}</b></p>: ''
                            }
                            
                            {
                                this.state.confirmData.type == 'is_active' ? this.state.confirmData.params.is_active ? <p>{this.props.t("Listing.active_category")} <b>{`"${this.state.confirmData.params.category_name}"`}</b></p>: <p>{this.props.t("Listing.inactive_category")} <b>{`"${this.state.confirmData.params.category_name}"`}</b></p>: ''
                            }
                            
                            
                            <div className="col-sm-6 col-md-12 form-group text-right">
                                <Button type="button" colclass="col-sm-12" onClick={this.closeConfirmModal} btnClass="btn btn-primary" name="closeconfirm" id="closeconfirm" title={this.props.t("Listing.cancel")} />
                                &nbsp;&nbsp;
                                <Button type="button" 
                                colclass="col-sm-12"   
                                onClick={() => { this.handleIfStatus(this.state.confirmData.params)}}   
                                btnClass="btn btn-success" name="ok" id="ok" title={this.props.t("Listing.OK")} />
                            </div>
                        </div>:'' }
                        
                    </ModalPopup>
                )}

                {this.state.ifEditCategoryPopup === false ? null : (
                    <ModalPopup
                        id="if_edit_category"
                        className="modal ViewHistory data-table"
                        title={`Edit Inventory Funding Category`}
                        modalClose={this.closeIfEditCategoryModel}
                    >
                        <IFCategoryManagement
                            adminUserData={this.state.adminUserData}
                            isEdit={true}
                            formData={this.state.formData}
                            closeIfEditCategoryModel={this.closeIfEditCategoryModel}
                        />
                    </ModalPopup>
                )}
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
        dealer_id: state.dealer_id,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData }),
    };
};
export default withTranslation("inventory_funding")(connect(mapStateToProps, mapDispatchToProps)(IFCategoryListing));
