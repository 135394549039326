import MasterService from "../../../service/MasterService";
import React, { useState, useEffect } from "react";
import { toast } from "react-toastify";
import LeadListFilter from "./LeadListFilter";
import LeadListTable from "./LeadListTable";
import queryString from "query-string";
import { withTranslation } from "react-i18next";
import { useParams, Redirect } from "react-router-dom";
import secureStorage from "../../../config/encrypt";
import { connect, useDispatch, useSelector } from "react-redux";

let initialTabCount = {
    docs_verification: 0,
    update_info: 0,
    credit_eval_check: 0,
    credit_eval_go_no_go: 0,
    dvf_form: 0,
    home_survey: 0,
    regular_addn_doc: 0,
    final_evaluation: 0,
    memo: 0,
    rejected: 0,
};

const LeadList = (props) => {
    const paramsData = queryString?.parse(window.location.search);
    const [loading, setLoading] = useState(false);
    const [filterData, setFilterData] = useState({});
    const [leadListData, setLeadListData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [activeTab, setActiveTab] = useState(paramsData?.status > 0 ? paramsData?.status : 1);
    const [tabCounts, setTabCounts] = useState(initialTabCount);
    const [redirect, setRedirect] = useState("");

    useEffect(() => {
        getLeadList(filterData, offset, activeTab);
    }, [offset]);

    const updateReduxData = async () => {
        const loginResponse = secureStorage.getItem("loginUserInfo");
        if (!loginResponse) {
            setRedirect("/login");
            return;
        }
        props.updateStateIntoRedux("LOGIN_USER", loginResponse);
        const userRole = loginResponse?.data?.user_data?.role;
        if (userRole !== "admin") {
            setRedirect("/");
        }
    };

    useEffect(() => {
        updateReduxData();
    }, []);

    const getLeadList = async (filterData, page, activeTab) => {
        setLoading(true);
        MasterService.post(`/dealer/inventory-funding/if_application_list_backend`, {
            page: page + 1 || 1,
            limit: 10,
            application_status: activeTab,
            ...filterData,
        }).then((response) => {
            setLeadListData([]);
            if (response && response.data && response.data.status === 200) {
                if (response.data.data.total) {
                    setPageCount(response.data.data.total);
                }
                setLoading(false);
                setLeadListData(response.data.data);
                setTabCounts(response.data.data.tab_status_count);
            } else {
                setLoading(false);
                toast.error(response.data.message);
            }
        });
    };

    const handlePageClick = (selectedPage) => {
        setOffset(selectedPage);
    };

    const submitFilterForm = (filter_data) => {
        let filterData = { ...filter_data };
        filterData.application_status = filterData.application_status || "1";
        setFilterData(filterData);
        setActiveTab(filterData.application_status);
        setOffset(0);
        getLeadList({ ...filterData });
    };

    const handleTabChange = (tab) => {
        setActiveTab(tab);
        getLeadList({ ...filterData, application_status: tab });
    };

    if (redirect) {
        return <Redirect to={"/admin/ac-manager"} />;
    }

    return (
        <>
            <div className="lead-list-outer container-fluid">
                <div className="top-heading">
                    <h1>IF Dealer Onboarding Listing</h1>
                </div>
                <div className="loan-filter-bg">
                    <div className="top-filter-bg">
                        <LeadListFilter filter_data={filterData} onSubmitFilter={submitFilterForm} handleTabChange={handleTabChange} />
                    </div>
                </div>
                <div>
                    <LeadListTable
                        offset={offset}
                        leadListData={leadListData}
                        loading={loading}
                        activeTab={activeTab}
                        handlePageClick={handlePageClick}
                        pageCount={pageCount}
                        tabCounts={tabCounts}
                        handleTabChange={handleTabChange}
                    />
                </div>
            </div>
        </>
    );
};

const mapStateToProps = (state) => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData }),
    };
};
export default withTranslation("if_application")(connect(mapStateToProps, mapDispatchToProps)(LeadList));
