import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch, NavLink, Redirect } from 'react-router-dom';
import BasicDetails from './BasicDetails';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import { withTranslation } from 'react-i18next';
import { DealerService, InventoryService } from '../../service';
import secureStorage from '../../config/encrypt';
import MasterService from '../../service/MasterService';

class SalesSupportAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      permission: {},
      role: null,
      redirect: false,
      errors: {},
      loading: true,
      responseMsg: null,
      urlprefix: props.urlprefix || null,
      dealer_hash_id: props.dealer_hash_id || null,
      dealer_id: null,
      basic_details: props.basic_details || {},
      owner_details: props.owner_details || {},
      dealership_list: [],
      ac_managerList: [],
      outlet_list: props.outlet_list || [],
      StateList: [],
      cityAllList: [],
      localityList: [],
      outlet_errors: [],
      kycMasterlist: [],
      dealership_list_filtered: {},
      getLanguagelist: [],
      dealerPremiumTypeList: [],
      stockCategoryTypeList: [],
      formSubType: [],
    };

    this.getBasicDetails = this.getBasicDetails.bind(this);
    this.getOwnerDetails = this.getOwnerDetails.bind(this);
    this.getDealertypeList = this.getDealertypeList.bind(this);
    this.getOutletList = this.getOutletList.bind(this);
  }

  updateReduxData = async () => {
    let loginResponse = secureStorage.getItem('loginUserInfo');
    let role = loginResponse && loginResponse.data && loginResponse.data.user_data && loginResponse.data.user_data.role;
    let isMyAccount = this.props.match.url.indexOf('/my-account') > -1;
    // eslint-disable-next-line eqeqeq
    if (role == 'admin' && !isMyAccount) {
      this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
      let dealer_hash_id = this.props.match.params.id;
      let urlprefix = '/sales-user/' + dealer_hash_id;
      // eslint-disable-next-line eqeqeq
      if (dealer_hash_id == 'basic-details') {
        urlprefix = '/sales-user';
        dealer_hash_id = '';
      }
      this.setState({ urlprefix: urlprefix, dealer_hash_id: dealer_hash_id });
      // eslint-disable-next-line eqeqeq
    } else if (role == 'dealercentral' && isMyAccount) {
      let permission = { dealerTypeViewOnly: true, cityViewOnly: true };
      this.props.updateStateIntoRedux('LOGIN_USER', loginResponse);
      let dealer_hash_id =
        loginResponse &&
        loginResponse.data &&
        loginResponse.data.dealer_data &&
        loginResponse.data.dealer_data[0] &&
        loginResponse.data.dealer_data[0].dealer_id_hash;
      if (!dealer_hash_id) return this.setState({ redirect: '/' });
      let subTab = (
        window.location.href.slice(-1) == '/' ? window.location.href.substr(0, window.location.href.length - 1) : window.location.href
      ).split('/my-account')[1];
      let urlprefix = this.props.match.url;
      urlprefix = urlprefix.slice(-1) == '/' ? urlprefix.substr(0, urlprefix.length - 1) : urlprefix;
      this.setState({ urlprefix: urlprefix, dealer_hash_id: dealer_hash_id, role, permission }, () => {
        if (!subTab) {
          // this.setState({ redirect: urlprefix + '/basic-details' })
          this.props.history.push(urlprefix + '/basic-details');
        }
      });
    } else {
      this.setState({ redirect: '/' });
    }
  };

  componentDidMount = async () => {
    await this.updateReduxData();
    await this.getLanguageList();
    await this.getAccountManagerList();
    await this.getCityList();
    // await this.getlocalityList();
    await this.getBasicDetails();
    await this.getDealertypeList();
    await this.getKycMasterDetails();
    await this.getDealerFormInfo();
  };

  handleClickNav = (name, e) => {
    const { dealer_hash_id } = this.state;
    if (!dealer_hash_id || dealer_hash_id == 'basic-details') {
      e.preventDefault();
    } else {
      this.getBasicDetails();
    }
  };

  getLanguageList() {
    MasterService.post('dealer/dealer/get_roles',{})
      .then((response) => {
        if (response.status == 200 && response.data.status == 200) {
          this.setState({
            getLanguagelist: response.data.data,
            dealerPremiumTypeList: response.data.data.dealer_premium_type,
            stockCategoryTypeList: response.data.data.stock_category,
          });
        } else if (response.status == 401 && response.data.status == 401) {
          this.setState({ redirect: true });
        }
      })
      .catch((error) => {});
  }
  getCityList() {
    InventoryService.cityList({})
      .then((response) => {
        if (response.data.status == 200 && response.status == 200) {
          this.setState({ StateList: response.data.data.state, cityAllList: response.data.data.city });
        } else {
          toast.error(response.data.message ? response.data.message : 'Error');
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }
  // Get locality list
  // getlocalityList() {
  //     InventoryService.localityList({}).then(response => {
  //         if (response.data.status == 200 && response.status==200) {
  //             this.setState({localityList: response.data.data})
  //         }else{
  //             toast.error( (response.data.message) ? response.data.message : "Error" )
  //         }
  //     }).catch(error => {
  //         toast.error(error.message);
  //     });
  // }
  getDealerFormInfo = () => {
    let tempFormSubType = [];
    if (this.state.dealer_hash_id) {
      let postdata = { dealer_id_hash: this.state.dealer_hash_id };
      DealerService.getDealerFormInfo(postdata).then((response) => {
        if (response.data.status == 200 && response.status == 200) {
          let responsedata = response.data;
          if (responsedata.data.length > 0) {
            responsedata.data.forEach((element) => {
              tempFormSubType.push(element.form_sub_type);
              this.setState({ formSubType: tempFormSubType }, () => {});
            });
          }
        }
      });
    }
  };
  getBasicDetails() {
    if (this.state.dealer_hash_id) {
      let postdata = { sales_id_hash: this.state.dealer_hash_id, };
    MasterService.post('dealer/dealer/sales_users_details',postdata)
        .then((response) => {
          if (response.data.status == 200 && response.status == 200) {
            let responsedata = response.data;
            let datasend = {};
            if (responsedata.data.length > 0) {
              datasend = responsedata.data[0];
              this.setState({ basic_details: {...datasend,id: datasend.id,role_id:datasend.role_id},loading: false }, () => {
                //this.getOwnerDetails();
                //this.getOutletList();
              });
            } else {
              //this.setState({ redirect: '/' });
            }
          } else {
            toast.error(response.data.message ? response.data.message : 'Error');
          }
        })
        .catch((error) => {
          toast.error(error.message);
        });
    } else {
      this.setState({ loading: false });
    }
  }

  getOwnerDetails() {
    let postdata = { dealer_id: this.state.dealer_id };
    DealerService.getOwnerDetails(postdata)
      .then((response) => {
        if (response.data.status == 200 && response.status == 200) {
          let responsedata = response.data;
          let datasend = {};
          if (responsedata.data.length > 0) {
            datasend = responsedata.data[0];
          }

          this.setState({ owner_details: datasend }, () => {});
        } else {
          toast.error(response.data.message ? response.data.message : 'Error');
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  getAccountManagerList() {
    DealerService.getAccountManagerList()
      .then((response) => {
        if (response.data.status == 200 && response.status == 200) {
          let responsedata = response.data;
          let ac_manager_list = responsedata.data;
          this.setState({ ac_managerList: ac_manager_list }, () => {});
        } else {
          toast.error(response.data.message ? response.data.message : 'Error');
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  getDealertypeList() {
    DealerService.getDealertypeList()
      .then((response) => {
        if (response.data.status == 200 && response.status == 200) {
          let responsedata = response.data;
          let dealershiplist = responsedata.data;
          this.setState({ dealership_list: dealershiplist }, () => {});
        } else {
          toast.error(response.data.message ? response.data.message : 'Error');
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  getKycMasterDetails() {
    DealerService.getKycMasterDetails()
      .then((response) => {
        if (response.data.status == 200 && response.status == 200) {
          let responsedata = response.data;
          let kycMasterlist = responsedata.data;

          this.setState({ kycMasterlist: kycMasterlist }, () => {});
        } else {
          toast.error(response.data.message ? response.data.message : 'Error');
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  getOutletList() {
    let postdata = {
      dealer_id: this.state.dealer_id,
      _with: [
        'id',
        'dealer_id',
        'name',
        'address',
        'description',
        'type',
        'contact_number',
        'is_primary',
        'city_id',
        'state_id',
        'locality_id',
        'pincode',
        'latitude',
        'longitude',
        'is_lat_long_verified',
        'website_url',
        'opening_timings',
        'lead_preference_cities',
        'can_lead_cities',
      ],
    };
    DealerService.getOutletList(postdata)
      .then((response) => {
        if (response.data.status == 200 && response.status == 200) {
          let responsedata = response.data;
          let datasend = responsedata.data;
          datasend = datasend.map((singledata) => {
            if (singledata.opening_timings == 'undefined' || singledata.opening_timings == null || singledata.opening_timings == '') {
              singledata.opening_timings = {
                Monday: { open: '10:00 AM', close: '7:00 PM', on: 'on' },
                Tuesday: { open: '10:00 AM', close: '7:00 PM', on: 'on' },
                Wednesday: { open: '10:00 AM', close: '7:00 PM', on: 'on' },
                Thursday: { open: '10:00 AM', close: '7:00 PM', on: 'on' },
                Friday: { open: '10:00 AM', close: '7:00 PM', on: 'on' },
                Saturday: { open: '10:00 AM', close: '7:00 PM', on: 'on' },
                Sunday: { open: '10:00 AM', close: '7:00 PM', on: 'on' },
              };
            }
            return singledata;
          });
          this.setState({ outlet_list: datasend }, () => {});
        } else {
          toast.error(response.data.message ? response.data.message : 'Error');
        }
      })
      .catch((error) => {
        toast.error(error.message);
      });
  }

  validateAndSaveBasicinfo = (event, owner_details, basic_details) => {
    this.setState({ loading: true }, () => {});
    // var parentObj = this;//need to remove
    event.preventDefault();
    if (owner_details.date_of_joining) {
      let date_of_joining = new Date(owner_details.date_of_joining);
      let join_date = date_of_joining.getFullYear() + '-' + (date_of_joining.getMonth() + 1) + '-' + date_of_joining.getDate();
      owner_details.date_of_joining = join_date;
    }

    if (owner_details.birth_date) {
      let birth_date = new Date(owner_details.birth_date);
      let birthdate = birth_date.getFullYear() + '-' + (birth_date.getMonth() + 1) + '-' + birth_date.getDate();
      owner_details.birth_date = birthdate;
    }
    let languageList = this.state.getLanguagelist;
    let lanLen = languageList ? languageList.length : 0;
    if (!basic_details.default_lang_id && lanLen == 1) {
      let langId = languageList[0].id;
      basic_details.default_lang_id = langId;
    }

    if (!basic_details.parent_dealer_type) {
      basic_details.parent_dealer_type = '1';
    }

    let data2save = Object.assign({}, basic_details);
    data2save.owner = owner_details;
    //data2save.owner.id = '';
    var METHOD = 'post';
    if (data2save.dealer_id_hash) {
      METHOD = 'put';
    }

    DealerService.saveBasicinfo(data2save, METHOD)
      .then((response) => {
        this.setState({ loading: false }, () => {});

        if (response.data.status == 200 && response.status == 200) {
          let responseData = response.data;
          let dealer_id_hash = responseData.data && responseData.data[0] && responseData.data[0].dealer_id_hash;
          let other = {};
          if (METHOD == 'post') {
            if (dealer_id_hash) {
              basic_details.dealer_id_hash = dealer_id_hash;
              other = {
                urlprefix: '/dealer/' + basic_details.dealer_id_hash,
                dealer_hash_id: basic_details.dealer_id_hash,
              };
            } else {
              return toast.error('Error In Create');
            }
          }

          this.setState({ basic_details: basic_details, owner_details: owner_details, ...other }, () => {
            toast.success(response.data.message ? response.data.message : 'Success');

            if (METHOD == 'post') {
              let url2go = '/dealer/' + basic_details.dealer_id_hash + '/outlet-info';
              this.props.history.push(url2go);
              // this.componentDidMount();
              window.location.reload();
            }
          });
        } else {
          this.setState({ basic_details: basic_details, owner_details: owner_details }, () => {
            toast.error(response.data.message ? response.data.message : 'Error');
          });
        }
      })
      .catch((error) => {
        toast.error(error.message);
        this.setState({ loading: true }, () => {});
      });
  };

 
  redirect_basic = (redirect) => {
    this.setState({ redirect})
  }

  render() {
    let { formSubType, basic_details } = this.state;
    if (this.state.redirect) {
      return <Redirect to={this.state.redirect} />;
    }
    // let masters = {//need to remove var not in use
    //   aaaaa: [],
    // };
    return (
      <div>
          <Router>
            <ul className="pagesteps">
              <li className="active">
                <NavLink exact activeClassName="active" to={this.state.urlprefix + '/basic-details'} onClick={this.getBasicDetails}>
                  <span className="pagecount">1</span>
                  {this.props.t('salessupportuserDetails.myAccount.Basic_Details')}
                  <span>
                    {formSubType.indexOf('basic_detail') != -1 && parseInt(basic_details.status) == 3 ? (
                      <i className="ic-peson-listed icons-normal"></i>
                    ) : parseInt(basic_details.status) == 3 ? (
                      <i className="ic-peson-blacklist icons-normal"></i>
                    ) : (
                      ''
                    )}
                  </span>
                </NavLink>
              </li>
            </ul>
            <div className="container-fluid">
              {this.props.children}
              <Switch>
                <Route exact path={this.state.urlprefix + '/basic-details'}>
                  <BasicDetails
                    loading={this.state.loading}
                    getLanguagelist={this.state.getLanguagelist}
                    basic_details={this.state.basic_details}
                    onClick={this.validateAndSaveBasicinfo}
                    redirect_basic={this.redirect_basic}
                    onChange={this.handleChangeEvent}
                    permission={this.state.permission}
                  />
                </Route>
              </Switch>
            </div>
          </Router>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData }),
  };
};
export default withTranslation('sales_support_users')(connect(mapStateToProps, mapDispatchToProps)(SalesSupportAccount));
