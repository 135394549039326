import React, { useState, useEffect, useRef } from "react";
import { useTranslation } from "react-i18next";
import { Tab, Nav } from "react-bootstrap";
import ReactPaginate from "react-paginate";
import { Link } from "react-router-dom";
import MasterService from "../../../service/MasterService";
import { toast } from "react-toastify";

const LeadListTable = (props) => {
    const { t } = useTranslation("dealers");
    const [HistoryList, SetHistoryList] = useState([]);
    const [showHistory, SetShowHistory] = useState(false);
    const [loading, setLoading] = useState(false);
    const [applicationID, setApplicationID] = useState(0);
    let pageCount = Math.ceil(props.pageCount / 10);
    useEffect(() => {}, []);

    const handlePagenationClick = (e) => {
        const selectedPage = e.selected;
        props.handlePageClick(selectedPage);
    };

    const getHistoryList = async (application_id) => {
        setLoading(true);
        MasterService.post(`/dealer/inventory-funding/if_application_history_list`, {
            application_id,
        }).then((response) => {
            SetHistoryList([]);
            setLoading(false);
            if (response && response.data && response.data.status === 200) {
                SetHistoryList(response.data.data);
                SetShowHistory(true);
            } else {
                toast.error(response.data.message);
            }
        });
    };

    const showHistoryList = (event, application_id) => {
        event.preventDefault();
        setApplicationID(application_id);
        getHistoryList(application_id);
    };

    const hideHistoryList = () => {
        SetShowHistory(false);
    };

    let leadList = props?.leadListData?.data || [];
    return (
        <>
            <div className={`lead-list-table-outer ${props.loading || loading ? "loading" : ""} `}>
                <div className="lead-list-tabs">
                    <Tab.Container id="left-tabs-example" activeKey={props.activeTab} onSelect={(tab) => props.handleTabChange(tab)}>
                        <Nav variant="pills" className="flex-column nav nav-pills">
                            <div className="tab-list">
                                <Nav.Item>
                                    <Nav.Link eventKey="11">
                                        Docs Pending <span>({props.tabCounts.docs_pending})</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="1">
                                        Docs Verification <span>({props.tabCounts.docs_verification})</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="2">
                                        Update Info <span>({props.tabCounts.update_info})</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="3">
                                        Credit Eval (Check) <span>({props.tabCounts.credit_eval_check})</span>
                                    </Nav.Link>
                                </Nav.Item>
                                <Nav.Item>
                                    <Nav.Link eventKey="4">
                                        Credit Eval (Go-No-Go) <span>({props.tabCounts.credit_eval_go_no_go})</span>
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="5">
                                        DVF Form <span>({props.tabCounts.dvf_form})</span>
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="6">
                                        Home Survey <span>({props.tabCounts.home_survey})</span>
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="7">
                                        Regular Addn Doc <span>({props.tabCounts.regular_addn_doc})</span>
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="8">
                                        Final Evaluation <span>({props.tabCounts.final_evaluation})</span>
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="9">
                                        Memo <span>({props.tabCounts.memo})</span>
                                    </Nav.Link>
                                </Nav.Item>

                                <Nav.Item>
                                    <Nav.Link eventKey="10">
                                        Rejected <span>({props.tabCounts.rejected})</span>
                                    </Nav.Link>
                                </Nav.Item>
                            </div>
                        </Nav>
                    </Tab.Container>
                </div>
                <div className="data-table">
                    <table>
                        <thead>
                            <tr>
                                <th>Application Number</th>
                                <th>Dealer Details</th>
                                <th>City</th>
                                <th>Submitted By</th>
                                <th>Submitted Date</th>
                                <th>Last Edited Date</th>
                                <th>Assigned To</th>
                                <th>Application Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {leadList.length == 0 ? (
                                <tr>
                                    <td align="center" colSpan="7">
                                        <h6 className="text-center text-danger text-bold">{t("Listing.Search_Result.No_Record_Found")}</h6>
                                    </td>
                                </tr>
                            ) : null}
                            {leadList.length != 0 &&
                                leadList?.map((lead, k) => (
                                    <tr key={k}>
                                        <td>{lead.application_id}</td>
                                        <td>
                                            <div className="dealer-table-detail-txt">
                                                <label>{lead.dealership_name}</label>
                                                {/* <span>{'mobile'}</span> */}
                                                <span>{lead.dealership_email}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <span>{lead.city_name}</span>
                                        </td>
                                        <td>
                                            <div className="dealer-table-detail-txt">
                                                <label>{lead.sfa_user_name}</label>
                                                <span>{lead.sfa_user_role}</span>
                                                <span>{lead.sfa_user_id}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <span>{lead.raised_date}</span>
                                        </td>
                                        <td>
                                            <span>{lead.updated_date}</span>
                                        </td>
                                        <td>
                                            <div className="dealer-table-detail-txt">
                                                <label>{lead.sfa_user_name}</label>
                                                <span>{lead.sfa_user_role}</span>
                                                <span>{lead.sfa_user_id}</span>
                                            </div>
                                        </td>
                                        <td>
                                            <span>{lead.application_status}</span>
                                        </td>
                                        <td>
                                            <ul>
                                                <ul class="action-btn">
                                                    <li class="" title="History">
                                                        <i class="ic-file-history" onClick={(e) => showHistoryList(e, lead.application_id)}></i>
                                                    </li>
                                                    <li class="" title="History">
                                                        <Link to={`/if-application-detail/${lead.application_id}/doc-verification`}>
                                                            <i className="ic-remove_red_eyevisibility"></i>
                                                        </Link>
                                                    </li>
                                                </ul>
                                            </ul>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    <div className="pagination-bx" id="dealerList">
                        <ReactPaginate
                            previousLabel={"<"}
                            nextLabel={">"}
                            breakLabel={"..."}
                            breakClassName={"break-me"}
                            pageCount={pageCount}
                            marginPagesDisplayed={2}
                            forcePage={props.offset}
                            pageRangeDisplayed={5}
                            onPageChange={handlePagenationClick}
                            containerClassName={"pagination"}
                            subContainerClassName={"pages pagination"}
                            activeClassName={"active"}
                        />
                    </div>
                    {showHistory && (
                        <div className="model-outer">
                            <Modal show={showHistory} handleClose={hideHistoryList}>
                                <div className="modal-header">
                                    <h2>History List (Application ID - {applicationID})</h2>
                                </div>
                                <div className="modal-body">
                                    <BasicDetails HistoryList={HistoryList} />
                                </div>
                                <div className="modal-footer"></div>
                            </Modal>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
};

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                {children}
                <button onClick={handleClose} className="close_icn">
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};

const BasicDetails = ({ HistoryList }) => {
    return (
        <table style={{ width: "100%", tableLayout: "fixed" }}>
            <thead>
                <tr>
                    <th>Created Date</th>
                    <th>Status Name</th>
                    <th>Created By Role</th>
                    <th>Created By</th>
                    <th>Remarks</th>
                </tr>
            </thead>
            <tbody>
                {HistoryList.map((item, idx) => {
                    return (
                        <tr>
                            <td>{item.created_date}</td>
                            <td>{item.status_name}</td>
                            <td>{item.role_name}</td>
                            <td>
                                <td>{item?.name && item?.id ? `${item.name} (${item.id})` : ``}</td>
                            </td>
                            <td>{item.remarks}</td>
                        </tr>
                    );
                })}
            </tbody>
        </table>
    );
};

export default LeadListTable;
