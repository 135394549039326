import React from "react";
import { useSelector } from "react-redux";

const IFApplicationRibbon = (props) => {
    const { if_application_details: applicationDetails } = useSelector((state) => state.ifApplication.applicationDetails);
    return (
        <div className="lead-detail-top-ribben">
            <ul>
                <li>
                    <label>Dealer Name</label>
                    <span>{applicationDetails?.dealership_name || "-"}</span>
                </li>
                <li>
                    <label>Application ID</label>
                    <span>{applicationDetails?.application_id || "-"}</span>
                </li>
                <li>
                    <label>Dealer Email Id</label>
                    <span>{applicationDetails?.dealership_email || "-"}</span>
                </li>
                <li>
                    <label>Dealer Phone No</label>
                    <span>{applicationDetails?.borrower_phone_number}</span>
                </li>
                <li>
                    <label>City</label>
                    <span>{applicationDetails?.city_name || "-"}</span>
                </li>
                <li>
                    <label>Mapped User</label>
                    <span className="text-highlight">
                        {applicationDetails?.sfa_user_name || "-"} - {applicationDetails?.created_by || "-"}{" "}
                    </span>
                </li>
            </ul>
        </div>
    );
};

export default IFApplicationRibbon;
