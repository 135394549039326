import MasterService from '../../../service/MasterService';
import React, { useState, useEffect } from 'react';
import { NavLink, Link } from 'react-router-dom';
import { toast } from 'react-toastify';

const Dashboard = () => {
  const [dashboardCards, setDashboardCards] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchFieldValue, setSearchFieldValue] = useState("");

  useEffect(() => {
    getDashboardCount();
  }, []);

  const getDashboardCount = async () => {
    setLoading(true);
    MasterService.post(`/dealer/inventory-funding/if_application_dashboard`).then((response) => {
      if (response && response.data && response.data.status === 200) {
        setLoading(false);
        let { data } = response.data;
        setDashboardCards(data);
      } else {
        setLoading(false);
        toast.error(response.data.message);
      }
    });
  };

  const handleChangetext = (event) => {
    setSearchFieldValue(event.target.value);
  };
  let searchUrl = '/if-application/lead-list?search_field_value=' + searchFieldValue;
  return (
    <div className="dashboard-bg">
      <div className="dashboard-outer">
        <div className="heading">
          <h1>Welcome to IF Dealer Onboarding</h1>
        </div>
        <div className="search-bx">
          <input onChange={handleChangetext} type="text" placeholder="Search by Dealer Name & Application ID" />
          <Link to={searchUrl}><button type="submit" className="search-btn">Search</button></Link>
          <i className="ic-search"></i>
        </div>
        <div className="d-flex">
          <div className="dashboard-card">
            {dashboardCards &&
              dashboardCards.map((reportList, index) => (
                <NavLink key={index} to={`/if-application/lead-list?status=${reportList.id}`} aria-label="refinance listing page">
                  <div className={`card color-${reportList?.card_styles?.card_color_id}`}>
                    <div className="roung-bg">
                      {reportList.card_styles && (
                        <i className={reportList.card_styles.icon_className}>
                          {reportList.card_styles.max_icon_paths
                            ? Array.from(Array(reportList.card_styles.max_icon_paths).keys()).map((i, indexkey1) => (
                                <i key={indexkey1} className={`path${i + 1}`}></i>
                              ))
                            : null}
                        </i>
                      )}
                    </div>
                    <span className="count-txt">{reportList.lead_count}</span>
                    <span className="card-txt">{reportList.status_name}</span>
                  </div>
                </NavLink>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default Dashboard;
