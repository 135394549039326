import MasterService from "../../../service/MasterService";
import React, { useState, useEffect } from "react";
import InputField from "./../../elements/Input";
import Select, { components } from "react-select";
import { useHistory } from "react-router-dom";
import queryString from "query-string";
const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
        </ValueContainer>
    );
};

const SearchType = [
    { value: "dealer_name", label: "Dealer Name" },
    { value: "application_number", label: "Application Number" },
];

const LeadListFilter = ({ filter_data, onSubmitFilter, handleTabChange }) => {
    const history = useHistory();
    const [filterData, setFilterData] = useState(filter_data);
    const [CityList, setCityList] = useState([]);
    const [Status, setStatus] = useState([]);

    useEffect(() => {
        setFilterOptionSelected();
        getFilterData();
    }, []);

    const getFilterData = () => {
        MasterService.post(`/dealer/inventory-funding/if_application_filter_data`)
            .then(function (response) {
                if (response.data.status == 200) {
                    setCityList(response.data.data.city_info);
                    setStatus(response.data.data.all_status);
                }
            })
            .catch(function (response) {});
    };
    const setFilterOptionSelected = () => {
        const paramsData = queryString?.parse(window.location.search);
        if (paramsData.search_field_value) {
            filterData["filter_type"] = "dealer_name";
            filterData["filter_value"] = paramsData.search_field_value;
            if (!isNaN(paramsData.search_field_value)) {
                filterData["filter_type"] = "application_number";
                filterData["filter_value"] = paramsData.search_field_value;
            }
        }
        setFilterData({ ...filterData });
    };
    const handleChange = (field_name, field_obj) => {
        let currval = "";
        if (field_obj.value) {
            currval = field_obj.value;
        }
        if (field_obj.id) {
            currval = field_obj.id;
        }
        filterData[field_name] = currval;
        setFilterData({ ...filterData });
    };
    const handleChangetext = (event) => {
        let obj = { ...filterData };
        obj[event.target.name] = event.target.value;
        setFilterData(obj);
    };
    const submitFilterForm = (event) => {
        event.preventDefault();
        if (typeof onSubmitFilter === "function") {
            onSubmitFilter(filterData);
        }
    };
    const resetFilterForm = (event) => {
        delete filterData.application_status;
        delete filterData.city;
        delete filterData.filter_type;
        delete filterData.filter_value;
        setFilterData(filterData);
        history.replace({ search: "" });
        event.preventDefault();
        if (typeof onSubmitFilter === "function") {
            onSubmitFilter(filterData);
        }
    };

    return (
        <>
            <form onSubmit={submitFilterForm}>
                <div className="lead-list-filter">
                    <div className="filter-filed-form">
                        <fieldset className="single-select">
                            <div className="material">
                                <Select
                                    components={{ ValueContainer: CustomValueContainer }}
                                    options={SearchType}
                                    onChange={handleChange.bind(this, "filter_type")}
                                    value={SearchType && SearchType.length && SearchType.filter(({ value }) => value === filterData.filter_type)}
                                    placeholder="Search Type"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                />
                            </div>
                        </fieldset>
                        <fieldset>
                            <div class="material">
                                <input
                                    type="text"
                                    name="filter_value"
                                    id="filter_value"
                                    class="form-input"
                                    placeholder=""
                                    value={filterData && filterData.filter_value ? filterData.filter_value : ""}
                                    disabled={filterData && filterData.filter_type ? false : true}
                                    onChange={(e) => handleChangetext(e)}
                                />
                                <label data-label="Enter Keyword" class="form-label"></label>
                            </div>
                        </fieldset>
                        <fieldset className="single-select">
                            <div className="material">
                                <Select
                                    components={{ ValueContainer: CustomValueContainer }}
                                    options={CityList}
                                    placeholder="City"
                                    onChange={handleChange.bind(this, "city")}
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    id="city_id"
                                    name="city_id"
                                    value={CityList && CityList.length && CityList.filter(({ id }) => id === filterData.city)}
                                    getOptionLabel={({ name }) => name}
                                    getOptionValue={({ id }) => id}
                                />
                            </div>
                        </fieldset>
                        <fieldset className="single-select">
                            <div className="material">
                                <Select
                                    placeholder="Application Status"
                                    className="react-select"
                                    classNamePrefix="react-select"
                                    onChange={handleChange.bind(this, "application_status")}
                                    components={{ ValueContainer: CustomValueContainer }}
                                    options={Status}
                                    id="application_status"
                                    name="application_status"
                                    value={Status && Status.length && Status.filter(({ id }) => id == filterData.application_status)}
                                    getOptionLabel={({ status_name }) => status_name}
                                    getOptionValue={({ id }) => id}
                                />
                            </div>
                        </fieldset>
                        <div class="btn-submit-reset">
                            <button class="btn-primary" type="submit">
                                Search
                            </button>
                            <button onClick={resetFilterForm} class="btn-reset">
                                Reset
                            </button>
                        </div>
                    </div>
                </div>
            </form>
        </>
    );
};

export default LeadListFilter;
