import React, { Component } from 'react';
import Button from '../../elements/Button';
import MasterService from './../../../service/MasterService';
import { withTranslation } from 'react-i18next';
import { toast } from 'react-toastify';
import { connect } from 'react-redux';

class Esigned extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dealer_id: this.props.eSign_dealer_id,
      signed_stamp_url: '',
      loading: false,
      signed_signature_url: '',
      signed_ktp_url: '',
      esign_status: '0',
    };
  }

  componentDidMount = () => {
    this.geteSignData();
  };

  geteSignData = async () => {
    try {
      this.setState({
        loading: true,
      });
      const response = await MasterService.post('dealer/esign/get_esign_docs', { dealer_id: this.state.dealer_id });
      if (response && response.data && response.data.status === 200) {
        this.setState({
          loading: false,
        });
        if (response && response.data && response.data.data) {
          this.setState({
            signed_stamp_url: response.data.data.signed_stamp_url,
            signed_signature_url: response.data.data.signed_signature_url,
            signed_ktp_url: response.data.data.signed_ktp_url,
            esign_status: response.data.data.esign_status,
          });
        } else {
        }
      } else {
        this.setState({
          loading: false,
        });
      }
    } catch (error) {}
  };

  updateEsignStatus = async (event, status) => {
    event.preventDefault();
    const response = await MasterService.post('dealer/esign/update_esign_status', { status, dealer_id: this.state.dealer_id });
    if (response.data.status == 200) {
      toast.success(response.data.message);
      this.props.closeEsignModel();
    } else {
      toast.error(response.data.message);
    }
  };

  render() {
    const { errors, signed_stamp_url, signed_signature_url, signed_ktp_url, esign_status } = this.state;
    return (
      <>
        <div className={this.state.loading ? 'loading row' : 'row'}>
          <div className="col-md-6">
            <p>{this.props.t('Listing.Search_Result.esign_ktp')}</p>
            <div class="funding-img esign-img">{signed_ktp_url ? <img src={signed_ktp_url} alt="" /> : <p>No KTP Image</p>}</div>
          </div>
          <div className="col-md-6">
            <p>{this.props.t('Listing.Search_Result.esign_upload')}</p>
            <div class="funding-img esign-img">
              {signed_signature_url ? <img src={signed_signature_url} alt="" height={'200px'} /> : <p>No Signature Image</p>}
            </div>
            <div class="funding-img esign-img">
              {signed_stamp_url ? <img src={signed_stamp_url} alt="" height={'200px'} /> : <p>No Stamp Image</p>}
            </div>
          </div>
        </div>
        <br></br>
        <br></br>
        <div className="row">
          {['1', '2'].includes(esign_status) && (
            <div className="col-sm-6 col-md-12 form-group text-right">
              <Button
                onClick={(e) => this.updateEsignStatus(e, '1')}
                colclass="col-sm-12"
                disabled={true}
                btnClass="btn btn-primary mrg-r15"
                type="reset"
                name="addleadbtn" 
                title={esign_status == '1' ? this.props.t('Listing.Search_Result.esign_verified') : this.props.t('Listing.Search_Result.esign_rejected')}
              />
            </div>
          )}

          {['0'].includes(esign_status) && signed_signature_url && signed_stamp_url && (
            <div className="col-sm-6 col-md-12 form-group text-right">
              <Button
                onClick={(e) => this.updateEsignStatus(e, '1')}
                colclass="col-sm-12"
                btnClass="btn btn-primary mrg-r15"
                name="verify"
                id="verify"
                title={this.props.t('Listing.Search_Result.esign_verify')}
              />
              <Button
                onClick={(e) => this.updateEsignStatus(e, '2')}
                colclass="col-sm-12"
                btnClass="btn btn-primary"
                name="reject_verification"
                id="reject_verification"
                title={this.props.t('Listing.Search_Result.esign_reject_verification')}
              />
            </div>
          )}
        </div>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    auth_token: state.authToken,
    user_loggedin: state.userLoggedIn,
    dealer_id: state.dealer_id,
  };
};

export default withTranslation('dealers')(connect(mapStateToProps)(Esigned));
