import MasterService from "../../../service/MasterService";
import React, { useState, useEffect, useRef } from "react";
import { toast } from "react-toastify";
import { withTranslation } from "react-i18next";
import { Redirect } from "react-router-dom";
import secureStorage from "../../../config/encrypt";
import { connect } from "react-redux";
import InputField from "./../../elements/Input";
import Select, { components } from "react-select";
import { Link } from "react-router-dom";
import ReactPaginate from "react-paginate";
import { InventoryService } from "./../../../service";
import JSZip from "jszip";
import { saveAs } from "file-saver";
import { Tab, Nav } from "react-bootstrap";

const { ValueContainer, Placeholder } = components;
const CustomValueContainer = ({ children, ...props }) => {
    return (
        <ValueContainer {...props}>
            <Placeholder {...props} isFocused={props.isFocused}>
                {props.selectProps.placeholder}
            </Placeholder>
            {React.Children.map(children, (child) => (child && child.type !== Placeholder ? child : null))}
        </ValueContainer>
    );
};

let initialTabCount = {
    completed: 0,
    pending: 0,
    rejected: 0,
};

const HomeSurveyList = (props) => {
    const [loading, setLoading] = useState(false);
    const [filterData, setFilterData] = useState({});
    const [leadList, setHomeSurveyData] = useState([]);
    const [offset, setOffset] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [redirect, setRedirect] = useState("");
    const [FilesInfo, SetfilesInfo] = useState([]);
    const [formData, setFormData] = useState({});
    const [activeTab, setActiveTab] = useState("completed");
    const [tabCounts, setTabCounts] = useState(initialTabCount);
    const [uploadDoc, SetUploadDoc] = useState(false);
    const [DocLength, SetDocLength] = useState(0);
    const [UploadDocApplicationID, setUploadDocApplicationID] = useState(0);
    const ref = useRef();

    useEffect(() => {
        getHomeSurveyList(filterData, offset, activeTab);
    }, [offset]);

    const updateReduxData = async () => {
        const loginResponse = secureStorage.getItem("loginUserInfo");
        if (!loginResponse) {
            setRedirect("/login");
            return;
        }
        props.updateStateIntoRedux("LOGIN_USER", loginResponse);
        const userRole = loginResponse?.data?.user_data?.role;
        if (userRole !== "admin") {
            setRedirect("/");
        }
    };

    useEffect(() => {
        updateReduxData();
    }, []);

    const get_url_extension = async (url) => {
        return url.split(/[#?]/)[0].split(".").pop().trim();
    };
    const downloadAll = async (docs, application_id) => {
        setLoading(true);
        let zip = new JSZip();
        let folder = zip.folder("collection");
        let i = 1;
        for (const sl of docs) {
            if (sl) {
                let extenion = await get_url_extension(sl);
                let fileUrl = sl;
                let fileBlob = await fetch(fileUrl).then(async (response) => await response.blob());
                let docFile = new File([fileBlob], "file_name");
                folder.file(`home_survey_file` + i + `.${extenion}`, docFile);
                i++;
            }
        }
        let fileName = `home_survey_${application_id}`;
        folder.generateAsync({ type: "blob" }).then((content) => saveAs(content, fileName));
        setLoading(false);
    };
    const updateStatus = async (status, application_id) => {
        if (window.confirm(`Are you sure want to change status ?`)) {
            setLoading(true);
            MasterService.post(`/dealer/inventory-funding/if_save_home_survey`, {
                update_type: "status_only",
                home_survey_request: status,
                application_id,
            }).then((response) => {
                setHomeSurveyData([]);
                setLoading(false);
                if (response && response.data && response.data.status === 200) {
                    getHomeSurveyList(filterData, offset, activeTab);
                } else {
                    setLoading(false);
                    toast.error(response.data.message);
                }
            });
        }
    };
    const getHomeSurveyList = async (filterData, offset, activeTab) => {
        setLoading(true);
        MasterService.post(`/dealer/inventory-funding/if_home_survey_list`, {
            page: offset || 1,
            limit: 10,
            home_survey_request: activeTab,
            ...filterData,
        }).then((response) => {
            setHomeSurveyData([]);
            if (response && response.data && response.data.status === 200) {
                if (response.data.data.total) {
                    setPageCount(response.data.data.total / 10);
                }
                setLoading(false);
                setHomeSurveyData(response.data.data.data);
                setTabCounts(response.data.data.tab_status_count);
            } else {
                setLoading(false);
                toast.error(response.data.message);
            }
        });
    };
    const handlePageClick = (selectedPage) => {
        setOffset(selectedPage.selected);
    };
    const submitFilterForm = () => {
        let filter_data = { ...filterData };
        setFilterData(filter_data);
        getHomeSurveyList(filter_data, offset, activeTab);
    };
    const handleChange = (field_name, field_obj) => {
        let currval = "";
        if (field_obj.value) {
            currval = field_obj.value;
        }
        if (field_obj.id) {
            currval = field_obj.id;
        }

        if (["application_number", "dealer_name"].includes(currval)) {
            filterData[field_obj.value] = "";
            filterData[field_name] = currval;
        } else {
            filterData[field_name] = currval;
        }
        setFilterData({ ...filterData });
    };
    const handleChangetext = (event) => {
        let obj = { ...filterData };
        if (filterData.filter_type == "application_number") {
            obj["application_number"] = event.target.value;
        } else {
            obj["dealer_name"] = event.target.value;
        }
        setFilterData(obj);
    };
    const resetFilterForm = (event) => {
        delete filterData.application_number;
        delete filterData.survey_status;
        delete filterData.survey_type;
        delete filterData.dealer_name;
        delete filterData.filter_type;
        delete filterData.filter_value;
        setActiveTab("completed");
        setOffset(0);
        getHomeSurveyList(filterData, offset, "completed");
    };
    const handleFile = (event) => {
        let filesInfo = [...FilesInfo];
        let notImage = false;
        let selectedFiles = Array.from(event.target.files);
        selectedFiles.forEach((file, index) => {
            if (!file.name.match(/\.(pdf|PDF)$/i)) {
                notImage = true;
            }
        });
        if (notImage) {
            ref.current.value = "";
            event.preventDefault();
            alert("One of the file is not an pdf");
            return;
        }
        selectedFiles.forEach((file, index) => {
            filesInfo.push(file);
        });

        if (filesInfo.length + DocLength > 5) {
            event.preventDefault();
            ref.current.value = "";
            alert(`Doc upload limit exceed,you have already added 5 docs`);
            return;
        }

        var formDataTemp = { ...formData };
        if (filesInfo && filesInfo.length) {
            let fileArr = [];
            let browseUrl = [];
            filesInfo.forEach((file, index) => {
                fileArr.push(file);
                browseUrl.push(URL.createObjectURL(file));
            });
            formDataTemp.file_data = filesInfo;
            SetfilesInfo(filesInfo);
            setFormData(formDataTemp);
        }
    };
    const homeSurveyDocsSubmit = (application_id, e) => {
        e.preventDefault();
        setLoading(true);
        let payload = { ...formData };
        payload.application_id = application_id;
        if (payload.file_data && payload.file_data.length) {
            var reqData = new FormData();
            reqData.append("upload_type", "dif_docs");
            reqData.append("lead_id", application_id);
            payload.file_data.forEach((file, index) => {
                reqData.append("images", file);
            });
            InventoryService.uploadFilesToS3(reqData, { "Content-Type": "multipart/form-data" })
                .then((result) => {
                    if (result.data.status == 200) {
                        payload["docs"] = result.data.data;
                        saveHomeSurveyDocs(payload);
                    }
                })
                .catch((error) => {
                    setLoading(false);
                });
        } else {
            saveHomeSurveyDocs(payload);
        }
    };
    const saveHomeSurveyDocs = (payload) => {
        delete payload.file_data;
        MasterService.post(`/dealer/inventory-funding/upload_home_survey_docs`, payload)
            .then(function (response) {
                if (response?.data?.status === 200) {
                    toast.success(response.data.message);
                    SetfilesInfo([]);
                    getHomeSurveyList(filterData, offset, activeTab);
                    SetUploadDoc(false);
                    setLoading(false);
                } else {
                    toast.error(response.data.message);
                    setLoading(false);
                }
            })
            .catch(function (response) {
                toast.error(response.message || response.data.message);
                setLoading(false);
            });
    };
    const handleTabChange = (tab) => {
        setActiveTab(tab);
        getHomeSurveyList({ ...filterData, home_survey_request: tab });
    };

    if (redirect) {
        return <Redirect to={"/admin/ac-manager"} />;
    }

    const SurveyStatus = [
        { value: "positive", label: "Positive" },
        { value: "negative", label: "Negative" },
    ];

    const SurveyType = [
        { value: "internal", label: "Internal" },
        { value: "external", label: "External" },
    ];

    const SearchType = [
        { value: "application_number", label: "Application Number" },
        { value: "dealer_name", label: "Dealer Name" },
    ];

    const showUploadDoc = (event, application_id, docs_length) => {
        event.preventDefault();
        SetDocLength(docs_length);
        setUploadDocApplicationID(application_id);
        SetUploadDoc(true);
    };

    const hideUploadDoc = () => {
        SetUploadDoc(false);
    };

    return (
        <>
            <div className="lead-list-outer container-fluid">
                <div className="top-heading">
                    <h1>Home Survey Request Tracking</h1>
                </div>
                <div className="loan-filter-bg">
                    <div className="top-filter-bg">
                        <div className="lead-list-filter">
                            <div className="filter-filed-form">
                                <fieldset className="single-select">
                                    <div className="material">
                                        <Select
                                            components={{ ValueContainer: CustomValueContainer }}
                                            options={SearchType}
                                            onChange={handleChange.bind(this, "filter_type")}
                                            value={SearchType && SearchType.length && SearchType.filter(({ value }) => value === filterData.filter_type)}
                                            getOptionLabel={({ label }) => label}
                                            getOptionValue={({ value }) => value}
                                            placeholder="Search Type"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                        />
                                    </div>
                                </fieldset>
                                <fieldset>
                                    <div class="material">
                                        <input
                                            type="text"
                                            name="filter_value"
                                            id="filter_value"
                                            class="form-input"
                                            placeholder=""
                                            value={filterData[filterData.filter_type] ? filterData[filterData.filter_type] : ""}
                                            disabled={filterData && filterData.filter_type ? false : true}
                                            onChange={(e) => handleChangetext(e)}
                                        />
                                        <label data-label="Enter Keyword" class="form-label"></label>
                                    </div>
                                </fieldset>
                                <fieldset className="single-select">
                                    <div className="material">
                                        <Select
                                            components={{ ValueContainer: CustomValueContainer }}
                                            options={SurveyStatus}
                                            placeholder="Survey Status"
                                            onChange={handleChange.bind(this, "survey_status")}
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            id="survey_status"
                                            name="survey_status"
                                            value={
                                                SurveyStatus && SurveyStatus.length && SurveyStatus.filter(({ value }) => value === filterData.survey_status)
                                            }
                                            getOptionLabel={({ label }) => label}
                                            getOptionValue={({ value }) => value}
                                        />
                                    </div>
                                </fieldset>
                                <fieldset className="single-select">
                                    <div className="material">
                                        <Select
                                            placeholder="Survey Type"
                                            className="react-select"
                                            classNamePrefix="react-select"
                                            onChange={handleChange.bind(this, "survey_type")}
                                            components={{ ValueContainer: CustomValueContainer }}
                                            options={SurveyType}
                                            id="survey_type"
                                            name="survey_type"
                                            value={SurveyType && SurveyType.length && SurveyType.filter(({ value }) => value == filterData.survey_type)}
                                            getOptionLabel={({ label }) => label}
                                            getOptionValue={({ value }) => value}
                                        />
                                    </div>
                                </fieldset>
                                <div class="btn-submit-reset">
                                    <button onClick={submitFilterForm} class="btn-primary" type="button">
                                        Search
                                    </button>
                                    <button onClick={resetFilterForm} class="btn-reset" type="button">
                                        Reset
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={`lead-list-table-outer ${loading ? "loading" : ""} `}>
                    <div className="lead-list-tabs">
                        <Tab.Container id="left-tabs-example" activeKey={activeTab} onSelect={(tab) => handleTabChange(tab)}>
                            <Nav variant="pills" className="flex-column nav">
                                <div className="tab-list">
                                    <Nav.Item>
                                        <Nav.Link eventKey="completed">
                                            Completed <span>({tabCounts.completed})</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="pending">
                                            Pending <span>({tabCounts.pending})</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="rejected">
                                            Rejected <span>({tabCounts.rejected})</span>
                                        </Nav.Link>
                                    </Nav.Item>
                                </div>
                            </Nav>
                        </Tab.Container>
                    </div>
                    <div className="data-table">
                        <table>
                            <thead>
                                <tr>
                                    <th>Application ID</th>
                                    <th>Dealership Details</th>
                                    <th>Survey Type</th>
                                    <th>Survey Request</th>
                                    <th>Survey Status</th>
                                    <th>Internal Stage</th>
                                    <th>Length of Stay</th>
                                    <th>Documents</th>
                                    <th>Survey Remarks</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {leadList.length == 0 ? (
                                    <tr>
                                        <td align="center" colSpan="7">
                                            <h6 className="text-center text-danger text-bold">{"No Record Found"}</h6>
                                        </td>
                                    </tr>
                                ) : null}
                                {leadList?.length != 0 &&
                                    leadList?.map((lead, k) => (
                                        <tr key={k}>
                                            <td>{lead.application_id}</td>
                                            <td>
                                                <div className="dealer-table-detail-txt">
                                                    <span>{lead.dealership_name}</span>
                                                    <span>{lead.borrower_phone_number}</span>
                                                    <span>{lead.dealership_email}</span>
                                                </div>
                                            </td>
                                            <td style={{ textTransform: "capitalize" }}>{lead.home_survey_type}</td>
                                            <td style={{ textTransform: "capitalize" }}>{lead.home_survey_request}</td>
                                            <td style={{ textTransform: "capitalize" }}>{lead.home_survey_status}</td>
                                            <td style={{ textTransform: "capitalize" }}>{lead.internal_survey_status} Stage</td>
                                            <td>{lead.length_of_stay}</td>
                                            <td>
                                                {lead.docs_data &&
                                                    lead.docs_data.map((img, index) => (
                                                        <>
                                                            <a href={img} target="_blank" rel="noreferrer">
                                                                View File
                                                            </a>
                                                            <br />
                                                        </>
                                                    ))}
                                                {lead.docs_data && lead.docs_data.length < 5 && (
                                                    <a href="#!" onClick={(e) => showUploadDoc(e, lead.application_id, lead.docs_data.length)}>
                                                        Upload Docs
                                                    </a>
                                                )}
                                            </td>
                                            <td>{lead.remarks}</td>
                                            <td>
                                                <ul>
                                                    <ul class="action-btn">
                                                        <li class="" title="Donwload">
                                                            <i className="ic-save_alt" onClick={() => downloadAll(lead.docs_data, lead.application_id)}></i>
                                                        </li>
                                                        <li class="" title="Edit">
                                                            <Link to={`/if-application-detail/${lead.application_id}/home-survey`}>
                                                                <i className="ic-createmode_editedit"></i>
                                                            </Link>
                                                        </li>
                                                        {lead.home_survey_request != "completed" && (
                                                            <li class="" title="completed">
                                                                <i className="ic-check" onClick={() => updateStatus("completed", lead.application_id)}></i>
                                                            </li>
                                                        )}
                                                        {!["rejected", "completed"].includes(lead.home_survey_request) && (
                                                            <li class="" title="rejected">
                                                                <i className="ic-cancel" onClick={() => updateStatus("rejected", lead.application_id)}></i>
                                                            </li>
                                                        )}
                                                        {lead.home_survey_request != "pending" && (
                                                            <li class="" title="pending">
                                                                <i className="ic-refresh" onClick={() => updateStatus("pending", lead.application_id)}></i>
                                                            </li>
                                                        )}
                                                    </ul>
                                                </ul>
                                            </td>
                                        </tr>
                                    ))}
                            </tbody>
                        </table>
                        <div className="pagination-bx" id="dealerList">
                            <ReactPaginate
                                previousLabel={"<"}
                                nextLabel={">"}
                                breakLabel={"..."}
                                breakClassName={"break-me"}
                                pageCount={pageCount}
                                marginPagesDisplayed={2}
                                forcePage={props.offset}
                                pageRangeDisplayed={5}
                                onPageChange={handlePageClick}
                                containerClassName={"pagination"}
                                subContainerClassName={"pages pagination"}
                                activeClassName={"active"}
                            />
                        </div>
                    </div>
                </div>
            </div>

            {uploadDoc && (
                <div className="model-outer">
                    <Modal show={uploadDoc} handleClose={hideUploadDoc}>
                        <div className="modal-header">
                            <h2>Upload Docs</h2>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <fieldset className="form-filed col-md-7">
                                    <div className="material">
                                        <input
                                            multiple
                                            type="file"
                                            useRef="image"
                                            id="upload-image-input"
                                            className="upload-image-input"
                                            ref={ref}
                                            accept="pdf/*"
                                            onChange={handleFile.bind(this)}
                                        />
                                    </div>
                                </fieldset>
                            </div>

                            <div className="row">
                                <fieldset className="form-filed col-md-7">
                                    <div className="material">
                                        <button className="btn btn-line" onClick={homeSurveyDocsSubmit.bind(null, UploadDocApplicationID)} type="button">
                                            Upload
                                        </button>
                                    </div>
                                </fieldset>
                            </div>
                        </div>
                        <div className="modal-footer"></div>
                    </Modal>
                </div>
            )}
        </>
    );
};

const Modal = ({ handleClose, show, children }) => {
    const showHideClassName = show ? "modal display-block" : "modal display-none";
    return (
        <div className={showHideClassName}>
            <section className="modal-main">
                {children}
                <button onClick={handleClose} className="close_icn">
                    <i className="ic-clearclose"></i>
                </button>
            </section>
        </div>
    );
};

const mapStateToProps = (state) => {
    return {
        auth_token: state.authToken,
        user_loggedin: state.userLoggedIn,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        updateStateIntoRedux: (actionType, stateData) => dispatch({ type: actionType, componentState: stateData }),
    };
};
export default withTranslation("if_application")(connect(mapStateToProps, mapDispatchToProps)(HomeSurveyList));
